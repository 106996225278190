import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				My Friend Pedro: Ripe for Revenge
			</title>
			<meta name={"description"} content={"Step into the thrilling world of action and revenge with your friend Pedro!"} />
			<meta property={"og:title"} content={"My Friend Pedro: Ripe for Revenge"} />
			<meta property={"og:description"} content={"Step into the thrilling world of action and revenge with your friend Pedro!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6724b0169e74900024fe1ef4/images/DALL_E_2024-11-01_15.40.56_-_A_small__minimalist_favicon_for_the_game__My_Friend_Pedro__Ripe_for_Revenge.__It_should_feature_a_banana_icon_with_a_playful_expression__wearing_sungl-removebg-preview.png?v=2024-11-01T14:04:09.468Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});